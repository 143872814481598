import { PortableText } from '@portabletext/react'
import { determineDocumentPathSync } from '@kaliber/sanity-routing/sanity'

import { routeMap } from '/routeMap'

import { ContentListUnordered, ContentListOrdered } from './ContentList'
import { ContentHeading } from './ContentHeading'
import { Link } from '/features/buildingBlocks/Link'
import ExpandableText from '/features/buildingBlocks/ExpandableText.universal'

import styles from './PortableText.css'

export function PortableTextMainContent({ value, layoutClassName = undefined }) {
  return <PortableTextBase className={cx(styles.componentMainContent, layoutClassName)} {...{ value }} />
}

export function PortableTextIntro({ value, layoutClassName = undefined }) {
  return <PortableTextBase className={cx(styles.componentIntro, layoutClassName)} {...{ value }} />
}

export function PortableTextCard({ value, layoutClassName = undefined }) {
  return <PortableTextBase className={cx(styles.componentCard, layoutClassName)} {...{ value }} />
}

function PortableTextBase({ value, className }) {
  return (
    <div className={cx(styles.componentBase, className)}>
      <PortableText components={componentsMainContent}  {...{ value }} />
    </div>
  )
}

export function  PortableTextCleanHtml({ blocks }) {
  return <PortableText value={blocks} components={componentsSimpleCleanHtml} />
}

/** @type {import('@portabletext/react').PortableTextProps['components']} */
export const componentsMainContent = {
  block: {
    normal: ({ children }) => <p className={styles.paragraph}>{children}</p>,
    heading: ({ children }) => <ContentHeading layoutClassName={styles.headingLayout}>{children}</ContentHeading>,
    subheading: ({ children }) => <h3 className={styles.subheading}>{children}</h3>,
    h1: ({ children }) => <ContentHeading layoutClassName={styles.headingLayout}>{children}</ContentHeading>,
    h2: ({ children }) => <ContentHeading layoutClassName={styles.headingLayout}>{children}</ContentHeading>,
    h3: ({ children }) => <h3 className={styles.subheading}>{children}</h3>,
  },
  list: {
    bullet: ({ children }) => <ContentListUnordered layoutClassName={styles.listLayout}>{children}</ContentListUnordered>,
    number: ({ children }) => <ContentListOrdered layoutClassName={styles.listLayout}>{children}</ContentListOrdered>,
  },
  listItem: (props) => <li className={styles.listItem}>{props.children}</li>,
  marks: {
    strong: (props) => <strong className={styles.strong}>{props.children}</strong>,
    em: (props) => <em className={styles.em}>{props.children}</em>,
    externalLink: ({ value, children }) => value.href && <Link targetSelf={value.targetSelf} href={value.href} dataX='link'>{children}</Link>,
    internalLink: ({ value, children }) => value.ref
      ? <Link href={determineDocumentPathSync({ document: value.ref, routeMap })} dataX='link'>{children}</Link>
      : <>{children}</>,
    expandableText: ({ value, children }) => <ExpandableText title={value.title} text={value.text}>{children}</ExpandableText>,
    sub: ({ children }) => <sub>{children}</sub>,
    sup: ({ children }) => <sup>{children}</sup>
  },
}

/** @type {import('@portabletext/react').PortableTextProps['components']} */
const componentsSimpleCleanHtml = {
  list: {
    bullet: ({ children }) => <ul>{children}</ul>,
    number: ({ children }) => <ol>{children}</ol>,
  },
  listItem: (props) => <li>{props.children}</li>,
  marks: {
    strong: ({ children }) => <strong>{children}</strong>,
    em: ({ children }) => <em>{children}</em>,
    underlined: ({ children }) => <u>{children}</u>,
    internalLink: ({ value, children }) => <a href={determineDocumentPathSync({ document: value.ref, routeMap })}>{children}</a>,
    externalLink: ({ value, children }) => <a href={value.href}>{children}</a>,
  },
  block: {
    normal: ({ children }) => <p>{children}</p>,
    heading: ({ children }) => <h2>{children}</h2>,
    subheading: ({ children }) => <h3>{children}</h3>,
  },
}
